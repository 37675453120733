<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="watch-podcast.png">
    <template v-slot:title> Watch & listen to videos, music and more</template>

    <template v-slot:description
      >Learn from people telling their stories of lived experience related to
      mental health and suicide through video, podcasts and song.
    </template>
  </Banner>

  <!-- VIDEOS-->
  <section class="tabs-sect">
    <div class="max-w-5xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
      >
        Videos
      </div>
      <p>
        In these videos, Elders and Knowledge Keepers share traditional
        teachings along with messages and words of encouragement.
      </p>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/SvePc8446XY"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Hard times & self acceptance
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/ZHwPyaaoL00"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Smudge
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/NnP365NWatQ"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Parent relationships & healing
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/IyMclloDxb8"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Residential school & healing
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/aytrKNz6xDE"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Hard times in romantic relationships
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/sbr1sJ1MFxQ"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Healing from trauma
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/iirGMvTEqfQ"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Learning from Elders
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/R0POsc0qEog"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Healing from abuse
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/Eg2cWsluwxo"
          title="YouTube video player"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Two-Spirit role and acceptance
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/UP2MScws6nA"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Relationship with Creator
      </div>
    </div>
  </section>

  <!-- PODCASTS -->
  <section class="my-10 tabs-sect md:my-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
      >
        Podcasts
      </div>
      <div class="grid grid-cols-1 gap-6">
        <div class="grid grid-cols-4 gap-0 md:gap-6">
          <div class="col-span-4 md:col-span-1">
            <div class="video-img">
              <iframe
                style="border-radius: 12px"
                src="https://open.spotify.com/embed/show/4mHQR9Xkhl5ZAOJndFA3uT?si=Bci_2PzqTKSpkKl5EO3DpQ"
                width="100%"
                height="240"
                allow="encrypted-media"
                class="w-full"
                title="Spotify"
              >
                Alternate content
              </iframe>
            </div>
          </div>

          <div class="col-span-4 video-desc md:col-span-3">
            <div class="mb-4 text-xl font-bold md:text-2xl">
              All My Relations on Spotify
            </div>
            <p>
              All My Relations is a podcast to explore our relationships to
              land, to our creatural relatives, and to one another. Each episode
              invites guests to delve into a different topic facing Native
              American peoples today. We keep it real, play some games, laugh a
              lot, and even cry sometimes. We invite you to join us!
            </p>
          </div>
        </div>
        <div class="grid grid-cols-4 gap-0 md:gap-6">
          <div class="col-span-4 md:col-span-1">
            <div class="video-img">
              <iframe
                style="border-radius: 12px"
                src="https://open.spotify.com/embed/show/1s1RTKnROf34NmE2mlvqx0?utm_source=generator"
                width="100%"
                height="240"
                title="Spotify"
                allow="encrypted-media"
              >
              </iframe>
            </div>
          </div>
          <div class="col-span-4 video-desc md:col-span-3">
            <div class="mb-4 font-bold md:text-2xl ttext-xl">
              Metis in Space on Spotify
            </div>
            <p>
              Métis In Space hilariously deconstructs the science fiction genre
              through a decolonial lense. Join hosts Molly Swain & Chelsea Vowel
              as they discuss decolonial perspective, review a sci-fi movie or
              television episode featuring Indigenous Peoples, tropes & themes.
            </p>
          </div>
        </div>
        <div class="grid grid-cols-4 gap-0 md:gap-6">
          <div class="col-span-4 md:col-span-1">
            <div class="video-img">
              <iframe
                style="border-radius: 12px"
                src="https://open.spotify.com/embed/show/1qKExfEtwCCEyW82nmk7kn?utm_source=generator"
                width="100%"
                height="240"
                title="Spotify"
                allow="encrypted-media"
              >
              </iframe>
            </div>
          </div>
          <div class="col-span-4 video-desc md:col-span-3">
            <div class="mb-4 text-xl font-bold md:text-2xl">
              2 Crees in a Pod on Spotify
            </div>
            <p>
              2 Crees in a Pod, unapologetically creates space for Indigenous
              resurgence. Our intention is to disrupt western colonial systems
              and honor Indigenous helping practices.
            </p>
          </div>
        </div>
        <div class="grid grid-cols-4 gap-0 md:gap-6">
          <div class="col-span-4 md:col-span-1">
            <div class="video-img">
              <iframe
                style="border-radius: 12px"
                src="https://open.spotify.com/embed/show/4Io6Fq4odWLgoqeYGImdbr?utm_source=generator"
                width="100%"
                height="240"
                title="Spotify"
                allow="encrypted-media"
              >
              </iframe>
            </div>
          </div>
          <div class="col-span-4 video-desc md:col-span-3">
            <div class="mb-4 text-xl font-bold md:text-2xl">
              The Unrooted Podcast by The Indigenous Foundation on Spotify
            </div>
            <p>
              The Indegenous Foundation is proud to present Unrooted, a podcast
              focused on centering Indigenour voices and stories. Through
              Unrooted, we hope to dismantle systems of oppression and 'uproot'
              the deeply ingrained issues and racism that exist against
              Indigenous peoples to this day.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- SONGS -->
  <section class="my-10 tabs-sect md:my-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
      >
        Songs
      </div>

      <div class="grid grid-cols-1 gap-6">
        <!-- song 1 -->
        <div class="grid grid-cols-4 gap-0 md:gap-6">
          <div class="col-span-4 md:col-span-1">
            <div class="video-img">
              <img
                class="w-full"
                src="../../assets/images/cree-morning-song.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="flex flex-col justify-between col-span-4 video-desc md:col-span-3"
          >
            <div class="mb-4 text-xl font-bold md:text-2xl">
              Cree Morning Song
            </div>

            <p class="mb-auto">
              Traditional songs have been validated by Cree-Elder pat Daigneault
              and sung by Adrian Goulet.
            </p>
            <span class="flex items-center mr-2.5">
              <a href="javascript:;" class="inline-block mr-2.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50px"
                  height="50px"
                  fill="currentColor"
                  class="bi bi-pause-circle-fill"
                  viewBox="0 0 16 16"
                  @click.prevent="playSound('Cree')"
                >
                  <path fill="#505050" :d="play_cree" />
                </svg>
              </a>

              Feb 8 • 1hr 9 min
            </span>
          </div>
        </div>
        <!-- song 2 -->
        <div class="grid grid-cols-4 gap-0 md:gap-6">
          <div class="col-span-4 md:col-span-1">
            <div class="video-img">
              <img
                class="w-full"
                src="../../assets/images/mother-earth-song.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="flex flex-col justify-between col-span-4 video-desc md:col-span-3"
          >
            <div class="mb-4 text-xl font-bold md:text-2xl">
              Mother Earth Song
            </div>
            <p class="mb-auto">
              Gingo Sa - Permission given by Cory Campbell - this teaching came
              from deceased Gizhi Baa Nodin "Whirlwind". The spirit of the song
              speaks to "you are the reason I walk this way". The teaching speak
              to most people who Sundance do so<ReadMore>
                for others loved ones who cannot.
              </ReadMore>
            </p>
            <span class="flex items-center mr-2.5">
              <a href="javascript:;" class="inline-block mr-2.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50px"
                  height="50px"
                  fill="currentColor"
                  class="bi bi-pause-circle-fill"
                  viewBox="0 0 16 16"
                  @click.prevent="playSound('MotherEatherSong1')"
                >
                  <path fill="#505050" :d="play_mother_earth_1" />
                </svg>
              </a>
              Feb 8 • 1hr 9 min
            </span>
          </div>
        </div>
        <!-- song 3 -->
        <div class="grid grid-cols-4 gap-0 md:gap-6">
          <div class="col-span-4 md:col-span-1">
            <div class="video-img">
              <img
                class="w-full"
                src="../../assets/images/tipi-mother-earth-song.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="flex flex-col justify-between col-span-4 video-desc md:col-span-3"
          >
            <div class="mb-4 text-xl font-bold md:text-2xl">
              Mother Earth Song A
            </div>
            <p class="mb-auto">
              Traditional songs have been validated by Cree-Elder Pat Daigneault
              and sung by Adrian Goulet.
            </p>
            <span class="flex items-center mr-2.5">
              <a href="javascript:;" class="inline-block mr-2.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50px"
                  height="50px"
                  fill="currentColor"
                  class="bi bi-pause-circle-fill"
                  viewBox="0 0 16 16"
                  @click.prevent="playSound('MotherEatherSong2')"
                >
                  <path fill="#505050" :d="play_mother_earth_2" />
                </svg>
              </a>
              Feb 8 • 1hr 9 min
            </span>
          </div>
        </div>
        <!-- song 4 -->
        <div class="grid grid-cols-4 gap-0 md:gap-6">
          <div class="col-span-4 md:col-span-1">
            <div class="video-img">
              <img
                class="w-full"
                src="../../assets/images/pow-wow-dancer.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-span-4 video-desc md:col-span-3">
            <div class="mb-4 text-xl font-bold md:text-2xl">
              Strong Woman Song
            </div>
            <p>
              Anishinabe kwewag and zhoganosh kwewag who were in solitary
              confinement in the Prison For Women in Kingston, Ontario during
              the 1970's.
            </p>

            <p class="py-4 mb-auto">
              The strong woman song is another honour song that was originally
              created<ReadMore>
                by a group of women in prison, looking to gain the strength and
                courage to persevere. It can be sung at any time we need the
                strength, courage and support.
              </ReadMore>
            </p>
            <span class="flex items-center mt-2">
              <a href="javascript:;" class="inline-block mr-2.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50px"
                  height="50px"
                  fill="currentColor"
                  class="bi bi-pause-circle-fill"
                  viewBox="0 0 16 16"
                  @click.prevent="playSound('StrongWoman')"
                >
                  <path fill="#505050" :d="play_strong_woman" />
                </svg>
              </a>
              Feb 8 • 1hr 9 min
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Audio -->
  <section class="tabs-sect">
    <div class="max-w-4xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl blue"
      >
        Audio
      </div>
      <p class="text-base md:text-xl">
        In this audio an Indigenous young woman shares some words of wisdom and
        encouragement.
      </p>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/F7NHMJ4zIY0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="mt-6 text-xl font-bold call-text md:text-3xl text-blue">
        Encouragement
      </div>
    </div>
  </section>

  <FooterCard
    img="try-another-coping.png"
    text="View Tools & Resources"
    name="ToolsAndResources"
  >
    <template v-slot:header>Try another coping strategy</template>
    <template v-slot:content>
      Different coping strategies resonate with different people.<br />
      Discover more coping strategies to add to your toolkit.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import FooterCard from "@/components/FooterCard.vue";
import ReadMore from "@/components/ReadMore.vue";
const Cree = require("@/assets/audio/Cree-morning-song-A.mp3");
const MotherEatherSong2 = require("@/assets/audio/Mother-Earth-song-2.mp3");
const MotherEatherSong1 = require("@/assets/audio/Mother-Earth-Song-A.mp3");
const StrongWoman = require("@/assets/audio/Strong-woman-song.mp3");
export default {
  name: "WatchVideosAndPodcasts",
  components: {
    Banner,
    ReadMore,
    FooterCard,
  },
  data() {
    return {
      play_cree:
        "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z",
      play_mother_earth_1:
        "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z",
      play_mother_earth_2:
        "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z",
      play_strong_woman:
        "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z",
      play: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z",
      pause:
        "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z",
    };
  },
  methods: {
    playSound(soundValue) {
      var isPlaying = this.isPlaying();
      console.log(isPlaying);
      if (isPlaying) {
        this.audio.pause();

        this.play_cree = this.play;
        this.play_mother_earth_1 = this.play;
        this.play_mother_earth_2 = this.play;
        this.play_strong_woman = this.play;
      }
      var sound = Cree;
      var stopPlaying = false;

      console.log("soundValue" + soundValue);

      if (soundValue === "Cree") {
        if (
          this.audio != null &&
          this.audio.src
            .toString()
            .toLowerCase()
            .trim()
            .includes(Cree.toString().toLowerCase().trim())
        ) {
          stopPlaying = true;
          this.$gtm.trackEvent({
            event: "gtm.audio",
            audioTitle: "Cree",
            audioStatus: "pause",
          });
          this.play_cree = this.play;
        } else {
          this.$gtm.trackEvent({
            event: "gtm.audio",
            audioTitle: "Cree",
            audioStatus: "play",
          });

          sound = Cree;
          this.play_cree = this.pause;
        }
      } else if (soundValue === "MotherEatherSong2") {
        console.log("MotherEarthSong2");
        if (
          this.audio != null &&
          this.audio.src
            .toString()
            .toLowerCase()
            .trim()
            .includes(MotherEatherSong2.toString().toLowerCase().trim())
        ) {
          stopPlaying = true;
          this.play_mother_earth_2 = this.play;

          this.$gtm.trackEvent({
            event: "gtm.audio",
            audioTitle: "Mother Earth Song A",
            audioStatus: "pause",
          });
        } else {
          this.$gtm.trackEvent({
            event: "gtm.audio",
            audioTitle: "Mother Earth Song A",
            audioStatus: "play",
          });

          sound = MotherEatherSong2;
          this.play_mother_earth_2 = this.pause;
        }
      } else if (soundValue === "MotherEatherSong1") {
        console.log("MotherEarthSong1");
        if (
          this.audio != null &&
          this.audio.src
            .toString()
            .toLowerCase()
            .trim()
            .includes(MotherEatherSong1.toString().toLowerCase().trim())
        ) {
          stopPlaying = true;
          this.play_mother_earth_1 = this.play;
          this.$gtm.trackEvent({
            event: "gtm.audio",
            audioTitle: "Mother Earth 1",
            audioStatus: "play",
          });
        } else {
          sound = MotherEatherSong1;
          this.play_mother_earth_1 = this.pause;
          this.$gtm.trackEvent({
            event: "gtm.audio",
            audioTitle: "Mother Earth 1",
            audioStatus: "pause",
          });
        }
      } else if (soundValue === "StrongWoman") {
        if (
          this.audio != null &&
          this.audio.src
            .toString()
            .toLowerCase()
            .trim()
            .includes(StrongWoman.toString().toLowerCase().trim())
        ) {
          stopPlaying = true;
          this.play_strong_woman = this.play;
          this.$gtm.trackEvent({
            event: "gtm.audio",
            audioTitle: "Strong Woman",
            audioStatus: "play",
          });
        } else {
          sound = StrongWoman;
          this.play_strong_woman = this.pause;
          this.$gtm.trackEvent({
            event: "gtm.audio",
            audioTitle: "Strong Woman",
            audioStatus: "pause",
          });
        }
      }

      if (stopPlaying) {
        this.audio.pause();
        this.audio = null;
      } else if (sound) {
        this.audio = new Audio(sound);
        this.audio.play();
      }
    },
    isPlaying() {
      if (this.audio) {
        return !this.audio.paused;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.video-desc {
  background-color: rgba(60, 94, 170, 0.06);
  height: 100%;
  padding: 30px;
}
.video-img {
  background-color: transparent;
  /* background-image: url("../../assets/images/relations.png"); */
}
</style>
