<template>
  <span class="cursor-pointer">
    <span v-if="!expanded">...</span>
    <a
      @click="toggleReadMore"
      v-if="!expanded"
      class="text-xs font-bold uppercase text-green"
      >Read more</a
    >
    <span v-if="expanded">
      <slot></slot>
      <a class="text-xs font-bold uppercase text-green" @click="toggleReadMore"
        >Read Less</a
      >
    </span></span
  >
</template>

<script>
export default {
  name: "ReadMore",
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleReadMore() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped></style>
